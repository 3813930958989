import { format, addMinutes, parse, differenceInMinutes, setHours, setMinutes, isAfter, isBefore } from "date-fns";
import { getSettings } from "../apis/setting";
const getFormattedHours = (startHour: any, endHour: any) => {
  const start = parse(startHour, "HH:mm", new Date());
  const end = parse(endHour, "HH:mm", new Date());

  let current = start;
  const result = [];

  while (current <= end) {
    result.push(format(current, "hh:mm a")); // Convert to 12-hour format
    current = addMinutes(current, 60); // Increment by 1 hour
  }
  return result;
};
const convertTo24Hour = (time12Hour: any) => {
  const date = parse(time12Hour, "hh:mm a", new Date());
  return format(date, "HH:mm");
};
const getNextAppointmentTime = (window: number) => {
  try {
    const result = getSettings().then((res: any) => {
      const settings = res.data.data;

      const openTimeSetting = settings.find(
        (setting: any) => setting.code === "open_time"
      );
      const closeTimeSetting = settings.find(
        (setting: any) => setting.code === "close_time"
      );
      if (openTimeSetting && closeTimeSetting) {
        const [openHours, openMinutes] = convertTo24Hour(openTimeSetting.value).split(":").map(Number);
        const [closeHours, closeMinutes] = convertTo24Hour(closeTimeSetting.value).split(":").map(Number);
        const openTime = setHours(setMinutes(new Date(), openMinutes), openHours);
        const closeTime = setHours(setMinutes(new Date(), closeMinutes), closeHours);

        const now = new Date();
        if (isBefore(now, openTime)) return new Date(openTime);
        if (isAfter(now, closeTime)) return new Date(closeTime);
        let minutes = now.getMinutes();
        const remainder = minutes % window;
        const addMinutes = remainder === 0 ? 0 : window - remainder;

        // // Set the future time with the calculated minutes
        now.setMinutes(minutes + addMinutes);
        return isAfter(now, closeTime) ? closeTime : now;

      }
    });
    return result;
  } catch (err) {
    return new Date();

  }
};

const getTimeDifferenceInHours = (time1: any, time2: any) => {
  // Parse the time strings into Date objects
  const date1 = parse(time1, "hh:mm a", new Date());
  const date2 = parse(time2, "hh:mm a", new Date());

  // Calculate the difference in minutes
  const minutesDifference = differenceInMinutes(date2, date1);

  // Convert minutes to hours
  return minutesDifference / 60;
};

export { getFormattedHours, getNextAppointmentTime, getTimeDifferenceInHours };
